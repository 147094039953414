@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus,
.MuiDataGrid-cell:focus-within {
  outline: none !important;
} 

.MuiDataGrid-root .MuiDataGrid-columnHeader {
  background-color: #f7fafc;
  border-right: 2px solid black;
  color: #6b7280;
  font-weight: 500;
  font-size: 16px;

}

.MuiDataGrid-root .MuiDataGrid-columnHeader:last-child {
  border-right: 0px solid black;

}

::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background-color: #39b4ac;
  border-radius: 20px;
}
